.headingtext{
    text-align: center;
    margin-top: 5rem;
    font-weight: 600;
font-size: 1.5rem;
}
.container{
    width: 90rem;
    height: 30rem;
    margin-left: 10rem;
    margin-top: 5rem;
    border: 1px solid #D9D9D9;
    border: none;
}
.keyheadings{
    margin-left: -66rem;
    font-weight:600 ;
    margin-top: 3rem;
}
ul{
list-style-type: none;
}
.ulproject .liproject::before {
    content: "\25AA";
    color: red;
    margin-left: 1rem;
    font-size: 25px;
    padding: 5px;
 }
.Back-EndDevelopment{
    margin-left: -20rem;
    font-weight:600 ;
    margin-top: -2.5rem;
}
.MobileAppDevelopment{
    margin-left: 25rem;
    font-weight:600 ;
    margin-top: -2.5rem;
}
.Containers{
    margin-left: 65rem;
    font-weight:600 ;
    margin-top: -2.5rem;
}
.UI {
    font-weight:600 ;
    margin-top: 11rem;
    margin-left:-70rem;
    padding-bottom: 10px;
}
.grapic{
    margin-left: -22rem;
    font-weight:600 ;
    margin-top: -9rem;
}
.data{
    margin-left: 27rem;
    font-weight:600 ;
    margin-top: -2.7rem;
}
.cloud{
    margin-left: 68rem;
    font-weight:600 ;
    margin-top: -2.5rem;
}



















