.chart-container {
  display: flex;
    margin-right: auto;
/* flex-direction: column; */
   
  }
  @media screen and (min-width:280px) and (max-width:1080px){
    .chart-container{
     display: flex;
     flex-direction: column;
     margin-top: 1rem;
    }
    }
    
    .chart-heading {
      text-align: center;
      font-size: 14px;
      margin-bottom: 10px;
    }
    
  .chart-wrapper {
    width: 160px;
    height: 160px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;
   
  }
  
  .labels-wrapper {
    text-align: center;
    margin-top: 16px;
   
  }
  
  .labels {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
    
  }
  
  .label-item {
    font-size: 12px;
  }
  .chart-wrapper:hover {
    width: auto; /* Set the width to "auto" on hover to allow the text box to expand */
  }