
.parent{

  /* UI Properties */
  box-shadow: 1px 3px 6px #00000029;
  border: 1px solid #D8D8D8;
  border-radius: 16px;
  opacity: 1;
  width: 70;
}
.new {
  column-count: 2;
  column-gap: 30px;
  width: 100%;
}

.container {
  margin-top: 140px;
  overflow-wrap: break-word;

}

.form-wrapper {
  width: 400px;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  padding: 20px 40px;
  border-radius: 10px;
  /* box-shadow: 0px 10px 50px #555; */
  background-color: #F8F8F8;
}
.oo1{
  padding: 10px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cfcfcf;
}
.head {
 
 
  /* UI Properties */
  text-align: center;
  font: Poppins;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-bottom: 50px;
  padding-top: 30px;
}
/* //candidatet img wala part  */
.imgadd{
  background-color: #5c6bf1;
  color: #fff;
  border: 2px solid #fff;
  width: 60%;
  margin-top: 1em;
  padding: 8px 0px;
  font-size: 1em;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 0.25em;
  position:absolute;
  top:27%;
  left:30%;
   text-align: center;
  cursor: pointer;
  
}
.imghold{
  width: 120px;
  height: 120px;
  object-fit: cover;
   position:absolute;
  top:2%;
  left:1%;
}
.img-holder2{
 width: 100px;
  height: 100px;
  position:absolute;
  top:8%;

  overflow: hidden;
  border-radius:49%;
  left:37%;
}

.l1{
  font-size: 0.8em;
  margin-bottom: 0.25em;
  color: #222;
  font-weight: lighter;
}

.input1 {
  padding: 10px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cfcfcf;
}


.inputphone{padding: 10px 10px;
  border: 1px solid #B7B7B7;
  border-radius: 10px;
  margin: 10px;
  width : 419px ;
 height:50px
}

@media screen and (min-width:280px) and (max-width:1080px){
  .inputphone{
    width : 219px ;
  height:50px
  }
  }

.input2{
   padding: 10px 10px;
   border: 1px solid #B7B7B7;
   border-radius: 10px;
   margin: 10px;
   width : 519px ;
  height:50px
}


@media screen and (min-width:280px) and (max-width:1080px){
  .input2{
    width : 219px ;
  height:50px
  }
  }


  
.input3{
  padding: 10px 10px;
  border: 1px solid #B7B7B7;
  border-radius: 10px;
  margin: 10px;
  width : 180px ;
 height:50px
}
@media screen and (min-width:280px) and (max-width:1080px){
  .input3{
    width : 219px ;
  height:50px
  }
  }

  .about2{
    padding: 10px 10px;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
    margin: 10px;
    width : 519px ;
   height:150px
 }
 
 @media screen and (min-width:280px) and (max-width:1080px){
   .about2{
     width : 219px ;
   height:100px
   }
   }



   .date{
    display: flex;
    border-radius: 2px solid grey;
   flex-direction: row;
   /* width : "100px"  */
  }
@media screen and (min-width:280px) and (max-width:1080px){
  .date{
    display: flex;
   flex-direction: column;
   /* width : "100px"  */
  }
  }

input.error {
  border: 1px solid red;
}

.errorMessage {
  color: red;
  font-size: 0.75em;
  display: relative;
}

a:hover {
  color: transparent;
  background-color: transparent;
}

.add {
  font-weight: bold;
  color: #8EAEBC;
  margin-left: 450px;
  background-color: transparent; /* Remove default background color */
  border: none; /* Remove default border */
  cursor: pointer; /* Show pointer cursor on hover */
}


@media screen and (min-width:280px) and (max-width:1080px){
  .add{
    display: flex;
   flex-direction: column;
   margin: auto;
  }
  }

.email{
  display: flex;
  flex-direction: row;
  overflow-wrap: break-word;
  align-items: center;
  
 
}
@media screen and (min-width:280px) and (max-width:1080px){
  .email{
    display: flex;
   flex-direction: column;
   margin: auto;
  }

}

.password {
  display: flex;
  flex-direction: row;
  overflow-wrap: break-word;
  align-items: center;
  
 
}
@media screen and (min-width:280px) and (max-width:1080px){
  .password{
    display: flex;
   flex-direction: column;
   margin: auto;
  }

}


.Label {
  display: flex;
  margin: auto;
width: 15rem;
font-weight: 600;
  overflow-wrap: break-word;
 
}
.startdate {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 8rem;
 
 
}
@media screen and (min-width:280px) and (max-width:1080px){
  .startdate{
   margin-left: 20px;
  width: 10rem;
  }
  }

.enddate{
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-left: 20px;
}
@media screen and (min-width:280px) and (max-width:1080px){
  .enddate{
   
  width: 10rem;
   margin: auto;
  }
  }


.createAccount {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createAccount button {
  background-color: #5c6bf1;
  color: #fff;
  border: 2px solid #fff;
  width: 100%;
  margin-top: 1em;
  padding: 8px 0px;
  font-size: 1em;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 0.25em;
}

.createAccount button:hover {
  color: #6f67e9;
  background-color: #fff;
  border: 2px solid #6270e6;
}

.createAccount small {
  color: #999;
  font-weight: lighter;
}


.headsmall{
 
/* UI Properties */
background: 0% 0% no-repeat padding-box;
opacity: 1;
color:  black;
font-weight: bold;
padding-bottom: 20px;
font-size: 30px

}

.profileclass{
  opacity:0;

}

.input4{
width: 519px;
border: 1px solid grey;
margin-left: 10px;
border-radius: 10px;
}


@media screen and (min-width:280px) and (max-width:1080px){

  .input4{
    width: 275px;
    border: 1px solid grey;
    margin-left: 10px;
    border-radius: 10px;
    }
    

}


.input6{
  border: 1px solid grey;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
  margin-left: 10px;
  }
  
  @media screen and (width:400px){

    .input6{
      border: 1px solid grey;
      border-radius: 10px;
      padding: 2px;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      }
      

      .addTo{
        margin-left: 150px;
      }
      
  
  }
  