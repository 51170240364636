.parent {
  /* UI Properties */
width: 400px;
text-align: center;
}

.loginSelect {
 

  width: 110px;
  height: 110px;
  margin-left: 55px;
  border: 1px solid grey;

  border-radius: 10px;
  padding:5px;
}

.loginSelect1 {
 

  width: 110px;
  height: 110px;
  
  border: 1px solid grey;

  border-radius: 10px;
  padding:5px;
}



     

    @media screen and (min-width:280px) and (max-width:1080px){
   
      .loginSelect {
       
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
  margin-top: 1rem;
     
       
            }            
    }

  

.centeritem {
  top: 179px;
left: 314px;
width: 1292px;
height: 1835px;
/* UI Properties */
border: 1px solid #D8D8D8;
border-radius: 16px;
opacity: 1;

}  

.login-label{
color: #1DBF73;
}
.homebutton{
text-align: center;
width: 107px;
height: 54px;
/* UI Properties */
border-radius: 10px;
opacity: 1;
background-color: #1DBF73;
color: white;
border: none;
justify-content: center;
box-shadow:1px 2px 3px #1DBF73;
float : center;
border-radius: 10px;
}
.homebutton2{
text-align: center;
 width: 200px;
 height: 100px;
 /* UI Properties */





justify-content: center;

float : center;
border-radius: 10px;
}
.login{

  top: 869px;
  left: 757px;
  width: 407px;
  height: 54px;
  /* UI Properties */
  border-radius: 10px;
  opacity: 1;
background-color: #1DBF73;
color: white;
border: 0px;
box-shadow: -15px -15px 25px #FFFFFF;
border-radius: 10px;


} 

.field {
display: flex;
justify-content: center;
align-items: center;

}

.register-header{
display: flex;
justify-content: center;
align-items: center;
color:  #8EAEBC;
font-weight: bold;

text-align: center;
}

.register-input{
display: flex;
justify-content: center;
align-items: center;
margin: auto;
width: 420px;
height: 54px; 
border: 1px solid #B7B7B7;
border-radius: 10px;
opacity: 1;
}

@media screen and (min-width:280px) and (max-width:1080px){
.register-input{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 54px; 
}
}

@media screen and (min-width:280px) and (max-width:1080px){
#showPass{
   margin:  30px;
   /* overflow : hidden; */
}
}
