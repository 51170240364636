.toolbar{
    position:fix;
    /* width: 100%; */
    /* background-color: white; */
    height:0;
}
 .toolbar_logo{
    top:1px;
    left: 38px;
    right: 12px;
    position: relative;
} 

.toolbar_navigation{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 2rem;
}

.spacer{
    flex:1;
}

.toolbar_item ul{
    list-style: none;
    margin: 0;
    display: flex;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 21px;
}

.toolbar_logo {
    /* color: white; */
    text-decoration: none;
    width: 20px;
    /* height:8px; */
    font-size: 0;
    padding: 0;
    /* padding-bottom: 2px; */
}
.logo1{
    top:2px;
    left:9px;
    right:82px;
    position: relative;
}
.toolbar_item{
    /* color: w; */
    height:100px;
font-size-adjust: inherit;
 text-decoration: none;
}
.toolbar_item a:hover,
.toolbar_item a:active{
    color: red;
}
/* .t1{
    height: 78px;
} */
.ty{
   /* font-size:34px ;  */
   font-family:-apple-system, BlinkMacSystemFont, sans-serif;
   font-weight: 200;
   color:#635b5b;
   /* height: 30px; */
}
.toolbar_logo2{
text-decoration: none;
top:30px;
width: 30px;
height:44px;
font-size: 0;
padding: 0;
}

a {
    text-decoration: none !important;
  }