
h1{
  font-size: 38px;
}

.itext {
  width: 30%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-radius: 15px;
  background-color: rgb(255, 249, 249);
  color: rgb(10, 10, 10);
  font-family: Arial, Helvetica, sans-serif;
}
.itext2{
  width:6%;
  left: 9%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-radius: 15px;
  background-color: rgb(255, 249, 249);
  /* color: rgb(10, 10, 10); */
  font-family: Arial, Helvetica, sans-serif;
}

.kk{
  width: 30%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-radius: 15px;
  background-color: rgb(255, 249, 249);
  color: rgb(10, 10, 10);
  font-family: Arial, Helvetica, sans-serif;
}

.isub {
  background-color: #ddd;
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  /* cursor: pointer; */
  border-radius: 16px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 22px;
}

select {
  width: 20%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  color: rgb(10, 10, 10);
  font-family: Arial, Helvetica, sans-serif;
}


.container{
  border-style: hidden;
  border-radius: 15px;
  padding:18px;
  text-align: center;
}
@media screen and (max-width:900px){

    .main-contianer{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-evenly; 
      
    }
  
  }

