.mainimage {
  background-image: url('../../Group94.jpg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
}


@media only screen and (min-width: 0px) and (max-width: 767px) {
  .columnsfeatures {
    display: flex;
    flex-direction: column;
   height: max-content;
  }
}

.fourthcontainer {
  background-image: url('../../Group 422.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 520px;
  margin-top: 40px;
  width: 100%;
}

.second-container {
  width: 100%;
  height: 688px;
  border-radius: 0px;
}

.third-container {
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  border-radius: 0px;
}

.fifth-container {
  width: 100%;
  height: 488px;
  background-color: #eaf5f9;
  border-radius: 0px;
}

.containerheader {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: black;
  padding-top: 45px;
  font-style: Poppins, Medium;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .containerheader {
    display: flex;
    flex-direction: column;
   height: max-content;
  }
}

.thirdcontainerheader {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: black;
  padding-top: 45px;
  font-style: Poppins, Medium;
   margin-top: -50px; 
}

.createprofile {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: black;
  padding-top: 195px;
  font-style: Poppins, Medium;
}

.secondimage {
  background-image: url('../../Group 917.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 520px;
  padding: 40px;
  width: 100%;
}

.sixth-container {
  width: 100%;
  height: 100%;
  background-color: #eaf5f9;
  border-radius: 0px;
}

.card {
  height: 100px;
  display: none;
  width: 100px;
  margin-left: 10rem;
  margin-top: 7rem;
  border: 1px solid #E2E2E2;
  box-shadow: 2px 2px 2px #00000029;
  border-radius: 10px;
}

.img1 {
  width: 60px;
  height: 60px;
}

.card2 {
  height: 100px;
  display: inline-block;
  justify-content: center;
  align-items: center;

  width: 100px;
  background-color: white;
  /* margin-top: 7rem; */
  border: 1px solid #E2E2E2;
  box-shadow: 2px 2px 2px #00000029;
  margin-left: 70px;
  /* margin-right: 80px; */
  border-radius: 10px;
}

.card1 {
  height: 150px;
  display: inline-block;
  width: 175px;
  margin-left: 5rem;
  border: 1px solid #E2E2E2;
  border-radius: 10px;
  box-shadow: 2px 2px 2px #00000029;
}

.nextitems {
  margin-top: 5rem;
}

.img {
  padding: 10px;
  width: 150px;
  height: 150px;
}

.names {
  font-weight: 600;
}

.names1 {
  font-weight: 700;
}

.items {
  margin-top: 5rem;
  margin-right: 80px;
}

.items1 {
 
  margin-top: 6rem;
  width: 60%;
  padding: 2rem;
  /* height: 300px; */
  background-color: #DEE9ED;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .items1 {
    display: flex;
    flex-direction: column;
   height: max-content;
  }
}

@media screen and (max-width: 0px) and (max-width: 767px)  {
  .card2 {
    height: 100px;
    width: 100px;
    background-color: white;
    margin-top: 4rem;
    border: 1px solid #E2E2E2;
    box-shadow: 2px 2px 2px #00000029;
    margin-left: -10px;
    margin-bottom: 40px;
    justify-content: center;
    text-align: center;
    margin-left: 50px;
  }

  .items1 {
    height: 100%;
    text-align: center;
    width: 100%;
  }

  .items {
    margin-right: 70px;
  }

  .lookingPicture {
    height: 400px;
    margin-right: 400px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .img {
    padding: 10px;
    width: 120px;
    height: 120px;
  }
}

@media only screen and (max-width: 0px) and (max-width: 767px)  {
  .fourthcontainer {
    background-image: url('../../Group 422.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 20px;
    margin-top: 240px;
  }
}
