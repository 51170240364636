/* Add this CSS to your component's stylesheet */
#timeChart canvas {
    position: relative;
  }
  
  #timeChart .chartjs-doughnut-tooltip {
    display: none;
  }
  
  #timeChart:hover .chartjs-doughnut-tooltip {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  