
  .account-header {
    background-color: #e5e5e5;
  }
  .item-flex {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
    padding: 10;
  }
  .item-detials {
    display: 'block' !important;
    margin: 20px;
  }
  .payment-input {
    width: 40%;
    padding: 10px;
    margin: 10px;
  }
  .payment-input-short {
    padding: 10px;
  }

  