/* Add your CSS styles for the popup component here */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-inner {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .chart-wrapper {
    width: 150px; /* Adjust the width as needed */
    height: 150px; /* Adjust the height as needed */
    margin: auto;
    text-align: center;
  }
 
  
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
  
  button {
    margin-top: 10px;
  }
  /* PopupDetailviewmetrics.css */
  .popup2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 800px; 
    height: 40rem;
    overflow-y:scroll;
    margin-top: 2rem;
    /* Adjust the width as needed */
  }
  
  .popup-content h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .popup-content h3 {
    font-size: 1rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .popup-content p {
    margin: 5px 0;
  }
  
  .popup-content button {
    margin-top: 20px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-content button:hover {
    background-color: #0056b3;
  }
  
/* Add more styles as needed */
