.posted{
  text-align: center;
  margin-top: 10rem;
  font-size: 1.5rem;
  font-weight: 600;
}
  .pagebutton{
    flex-direction: row;
    display: inline-block;
    padding: 2rem;
}
.select {
  margin-top: 10px;
  width: 27rem;
  height: 3rem;
  border: 0px solid white;
outline: none !important;
font-size: 18px;
font-family: Poppins, Regular;
font-weight: 600;
  color: black;
  text-decoration: none;
}
.table{
  width: 105%;
  border:1px solid #EFEFEF;
  margin-left: 5rem;

}

.main{
  background-color: white;
  margin-top: 1rem;
padding: 0.5rem;
  margin: auto;
  padding-bottom: 1rem;
}
.jobsearch {
margin-top: 10px;
width: 30rem;
height: 3rem;
outline: none !important;
font-size: 18px;
font-family: Poppins, Regular;
font-weight: 600;
color: black   !important;
border: 0px solid white;
border-right: 1px solid #B7B7B7;
}
.singlecontainer{
  background-color: white;
/* padding: 0.5rem; */
  border-bottom: 1px solid #B7B7B7 ;
  margin: auto;
}
.options{
  width: 250px;
  border-radius: 2px;
  height: max-content;
 margin-left: 1.5rem;
 margin-top: 1rem;
 font-size: 16px;
 font-weight: 500;
 border: 1px solid #A2A2A2;
 font-family: Poppins,Regular;
}
.optionsaplly{
  border-radius: 2px;
  height: 37px;
 margin-left: 1.5rem;
 margin-top: 1rem;
 font-size: 16px;
 border: 1px solid #A2A2A2;
 font-family:Poppins, Regular ;
}
.options1{
  width: 195px;
  border-radius: 2px;
  height: 37px;
 margin-left: 1.5rem;
 margin-top: 1rem;
 font-family:Poppins, Regular;
 font-size: 16px;
}
.headingcontent{
  display: flex;
  margin: auto;
}
.clear{
  margin-left: 2rem;
  margin-right: 3rem;
}
.pageNumbers {
  display: flex;
list-style: none;
flex-direction: row;
 justify-content: center;
 align-items: center;
}
.ol{
  list-style: none;
  margin-top: 2rem;
  padding-left: 20px;
  width: 95%;
}
.smg{
  height: max-content;
  width: 100%;
  background-color: white;
  border: 1px solid #D9D9D9;
  margin-top: 2rem;
  border-radius: 5px;
  padding: 10px;
}
.name{
display: flex;
flex-direction: row;
 align-items: center;
 font-size: 14px;
}
.firstname{
  margin-left: 20px;
 margin-top: 1rem;
  font-weight: 600;
display: flex;
justify-content: center;
text-align: center;
font-family: Poppins, Medium;
font-size: 15px;
color: black;
}
.name2{
  display: flex;
  flex-direction: row;
   align-items: center;
  }
.name1{


  font-weight: 600;
  font-family: Poppins, Medium;
  font-size: 15px;
  color: black;
  }
  .skills{
    display: flex;
   font-weight: 600;
    font-family: Poppins, Medium;
    font-size: 15px;
    color: black;
  }
  .place{
    margin-left: 20px;
    /* width: 50px;
  height: 20px; */
  margin-top: 1rem;
  padding: 3px;
  /* display: flex; */
  /* justify-content: center;
  text-align: center; */
  /* background-color:  #1DBF73; */
  background-color: rgba(29, 191, 115 ,20%);
  font-family: Poppins, Regular;
  font-weight: 600;
  font-size: 14px;
  color: black;
  border-radius: 5px;
  /* background-color:#00B4D8; */
  }
  .skill{
    font-size: 15px;
    color: black;
    font-family: Poppins, Medium;
  }
  .about{
    display: flex;
    margin: auto;
    margin-bottom: 1rem;
  font-size: 15px;
  color: #8D8D8D;

  font-weight: 600;
  font-family: Poppins, Regular;
  }
  .budget{
    display: flex;
  margin-left: auto;
  font-family: Poppins, Regular;
  font-size: 20px;
  color: black;
  /* border-radius: 5px; */
  }
  .budget1{
    display: flex;
  margin-left: auto;
  }
  .sub{
    font-family: Poppins, Regular;
    font-size: 15px;
    color: white;
    border-radius: 5px;
    border: none;
    width: 150px;
  height: 30px;
    background-color: #1DBF73;
    box-shadow: 15px 15px 25px #FFFFFF;
  }
  .filter{
  display: flex;
  margin-left: auto;
  }
  .time{
    margin-top: -0.2rem;
  display: flex;
  flex-direction: row;
   align-items: center;
  }
  .time1{
    font-size: 14px;
    padding: 5px;
    background-color: rgba(142, 174, 118 ,20%);
   font-family: Poppins, Regular;
   font-weight: 600;
   font-size: 14px;
   color: black;
   border-radius: 5px;
   
  }

 
  .role{
    margin-left: 20px;
    /* width: 200px;
  height: 20px; */
  padding: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  /* background-color:  #1DBF73; */
  background-color: rgba(142, 174, 118 ,20%);
  font-family: Poppins, Regular;
  font-weight: 600;
  font-size: 14px;
  color: black;
  border-radius: 5px;
  }

  .review{
   
   
   
    padding: 5px;
    display: flex;
    justify-content: center;
    text-align: center;
margin-right: auto;
    background-color: rgba(142, 174, 118 ,20%);
    font-family: Poppins, Regular;
    font-weight: 600;
    font-size: 14px;
    color: black;
    border-radius: 5px;
   
  }
 
  .pageNumbers li  {
  background: none;
    cursor: pointer;
   margin-top: 1rem;
    display: flex;
    justify-content: center;
    margin-left: 1.875rem;
    text-align: center;
  }
  .pageNumbers li.active{
   background-color: #00B4D8 ;
   color: white;
  border: 1px solid  #00B4D8;
  width:29px ;
  height: 27px;
  border-radius: 5px;
  }
  .pageNumbers li button{
    border: none;
    color: #A2A2A2;
    cursor: pointer;
    background: none;
    margin-left: 1.875rem;
    margin-top: 1rem;
  }
  .pageNumbers li button:hover{
    text-decoration: underline;
  }
  .pageNumbers{
    display: flex;
    margin-right: auto;
   flex-direction: row;
  }
  .length{
    color: #4E8FAE;
    padding-left: 20px;
  }
  
  
  button.close {
    background: #d73e4d;
    background: rgba(215, 62, 77, 0.75);
    border: 0 none !important;
    color: #fff7cc;
    display: inline-block;
  
    font-size: 34px;
    height: 40px;
    line-height: 1;
    margin: 0px 1px;
    opacity: 1;
    text-align: center;
    text-shadow: none;
    -webkit-transition: background 0.2s ease-in-out;
    transition: background 0.2s ease-in-out;
    vertical-align: top;
    width: 46px;
}







