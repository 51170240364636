.name2{
    display: flex;
    flex-direction: row;
     align-items: center;
     padding-top: 2rem;
    }
    .name{
        padding-top: 15px;
        padding-bottom: 10px;
        
       
    }
    .TR{
 display: flex;
 flex-direction: row;
    }

    .TD{
        width: 8rem;
         overflow-wrap: anywhere; 
        margin-bottom: auto;
        font-weight: 600;
        font-family: Poppins,medium;
        
      
    }

    .numbers {
        display: flex;
        list-style: none;
        flex-direction: row;
         justify-content: center;
         align-items: center;
         margin-bottom: 2rem;
      }
   
    .numbers li  {
        background: none;
          cursor: pointer;
         margin-top: 1rem;
          display: flex;
          justify-content: center;
          margin-left: 1.875rem;
          text-align: center;
         
        }
        .numbers li.active{
         background-color: #00B4D8 ;
         color: white;
        border: 1px solid  #00B4D8;
        width:29px ;
        height: 27px;
        border-radius: 5px;
        }
        .numbers li button{
            border: none;
            color: #A2A2A2;
            cursor: pointer;
            background: none;
            margin-left: 1.875rem;
            margin-top: 1rem;
          }

          .numbers li button:hover{
            text-decoration: underline;
          }