
.headingtext1{
  font-family: Poppins,Medium;
  font-weight: 600;
    text-align: center;
    margin-top: 5rem;
    margin-left: 330px;
  
font-size: 1.5rem;
}

.postreqcontainer{
  font-family: Poppins,Medium;
  font-weight: 600;
    width: 60rem;
    height: 1100px;
    box-shadow: 1px 3px 6px #00000029;
border-radius: 16px;
margin-left: 18rem;
background-color: #F8F8F8;
margin-top: 5rem;
    border: 1px solid #D8D8D8;
   
}

.postfield {
  font-family: Poppins,Medium;
  font-weight: 600;
    padding: 10px;

    margin-left: 19rem;
 }
 
 .projectinput{
  width: 407px;
  font-family: Poppins,Medium;
  font-weight: 600;
  height: 54px;
   border: 2px solid #B7B7B7;
   border-radius: 10px;
   padding-left: 10px;
   margin-left: 21rem;
   
 
 }
 .dropdown{
  width: 18rem;
  font-family: Poppins,Medium;
  font-weight: 600;
  height:50px;
  border-radius: 10px;
  border: 2px solid #B7B7B7;

}

select{
  color: #808080;
  font-family: 'Poppins, Regular';
 
  
  
}

#frontendSkills{
  border:1px solid #B7B7B7;
  width: 407px;
height: 54px;
font-family: Poppins,Medium;
  font-weight: 600;
}
#backendSkills{
  border:1px solid #B7B7B7;
  width: 407px;
height: 54px;
font-family: Poppins,Medium;
  font-weight: 600;
}
#mobileSkills{
  border:1px solid #B7B7B7;
  width: 407px;
height: 54px;
font-family: Poppins,Medium;
  font-weight: 600;
}
#workHours{
  border:1px solid #B7B7B7;
  width: 407px;
height: 54px;
font-family: Poppins,Medium;
  font-weight: 600;
}
#budget{
  border:1px solid #B7B7B7;
  width: 407px;
height: 54px;
font-family: Poppins,Medium;
  font-weight: 600;
}


.postbutton{
  background-color: #1DBF73;
 color: white;
font-size: 15px;
  border-radius: 10px;
  border: 2px solid #B7B7B7;
  height: 54px;
     width:407px;
     font-family: Poppins,Medium;
  font-weight: 600;
     margin-left: 19.8rem;
     box-shadow: 15px 15px 25px #FFFFFF;
}

.homebutton{
  background-color: #1DBF73;
  color: white;
 font-size: 15px;
   border-radius: 10px;
   height: 54px;
      width:209px;
      font-family: Poppins,Medium;
  font-weight: 600;
      box-shadow: 15px 15px 25px #FFFFFF ;
}
.button2{
  background-color: pink;
  border-radius: 10px;
  border: 2px solid #B7B7B7;
  height: 40px;
     width:18rem;
     font-family: Poppins,Medium;
  font-weight: 600;
     margin-left: 21.5rem;
}
.button1{
  background-color: pink;
  border-radius: 10px;
  border: 2px solid #B7B7B7;
  height: 40px;
     width:10rem;
     margin-left: 30rem;
     margin-top: -2rem;
     font-family: Poppins,Medium;
  font-weight: 600;
}

/* ProjectReqPostedSuccessfully */

.posted{
  text-align: center;
  margin-top: 10rem;
  font-size: 1.5rem;
  font-family: Poppins,Medium;
  
  font-weight: 600;
}